.form_donation_outer_div{
    background-color: #fff8f6;

    margin-left: 15%;
    margin-right: 15%;
    padding: 2%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.donor_details_head{
    font-family: 'Secular One', sans-serif;
    font-size: 1.3rem;
    margin-bottom: 2vh;
}

.introducer_details_head{
    font-family: 'Secular One', sans-serif;
    font-size: 1.3rem;
    margin-bottom: 2vh;
}


.paynow_btn{
    border: 2px solid #f1592a;
    background-color: #f1592a;

    border-radius: 0%;
    font-weight: 600;
    width: 20vw;
    height: fit-content;
    margin-top: 5vh;
    margin-bottom: 5vh;
    font-family:'Kanit', sans-serif;;
    font-size: 3vh;
}

.paynow_btn:hover{
    border: 2px solid #f1592a;
    color: #f1592a;
    background-color: white;
}


.generate_link_btn{
    border: 2px solid #00A300;
    background-color: #00A300;

    border-radius: 0%;
    font-weight: 600;
    width: 20vw;
    height: fit-content;
    margin-top: 5vh;
    margin-bottom: 5vh;
    font-family:'Kanit', sans-serif;;
    font-size: 3vh;
}

.generate_link_btn:hover{
    border: 2px solid #00A300;
    color: #00A300;
    background-color: white;
}




.amount_outer_div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    margin-left: 10vw;
    margin-right: 10vw;
    margin-bottom: 7vh;
}

/* .amount_outer_div > div{
    
} */

.defined_amount_div{
    /* border: 2px solid red; */
    background-color: #585858;
    padding: 20px;
    color:white;
    height: 100%;

    border-radius: 2px;
}
.donation_amount_custom_box > input{
    border: 5px solid #f1592a;
    /* padding: 20px; */
}

.donation_amount_custom_box{
    font-weight: 800;
}

.donation_form_head{
    font-family: 'Poppins', sans-serif;
    font-weight: 900;
    font-size: 2.5rem;
}

.donation_form_subhead{
    color:#727272;
    text-align: center;
    margin-bottom: 3rem;
    margin-top: 1rem;
}
.donation_form_note{
     color:red;
     text-align: center;
     margin-bottom: 1rem;
}
.donation_form_note2{
    color:red;
     text-align: center;
     margin-bottom: 4rem;
}