


.navbar_div{
    background-color: #000;
    height: 30vh;
    width: 100%;

    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    align-items: center;
    justify-content: space-between;
}

.logo{
    display: flex;
    flex-direction: column;

    margin-left: 2vw;
    height: 20vh;
    width: fit-content;

    background-color: white;

    /* justify-content: center; */
    align-items: center;

}
.logo > img{
    height: inherit;
    width: inherit;

}
.logo > div{
    height: fit-content;
    width: fit-content; 
    color:white;
    background-color: #f1592a;
    text-align: center;
    font-weight: 500;
    /* font-size: 2%; */
}

.btn_home{
    color: white;
    background-color: #f1592a;
    padding: 15px;
    font-weight: 700;
    margin-right: 10vw;
    
}

