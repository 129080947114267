.outermost-loading-div{
    color:#f1592a;
    position: absolute;
    background-color: white;
    height: 100vh;
    width: 100vw;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size:xx-large;
    font-weight: 700;
    z-index: 500;
}