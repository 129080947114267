.footer-link{
    color: #f1592a;
    text-decoration: none;
}
.footer_outer_div{
    width: 100vw;
    height: 10vh;
    background-color: black;
    color: rgba(255,255,255,0.5);
    display: flex;
    align-items: center;
    justify-content: center;

}